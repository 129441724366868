if (document.querySelector('.ms-timeline')) {
  var slider = document.querySelector('.ms-timeline');
  var isDown = false;
  var startX;
  var scrollLeft;

  slider.addEventListener('mousedown', function (e) {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', function () {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mouseup', function () {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mousemove', function (e) {
    if (!isDown) return;
    e.preventDefault();
    var x = e.pageX - slider.offsetLeft;
    var walk = (x - startX) * 3; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
    console.log(walk);
  });
}

jQuery(".find-the-exact-app .nav-pills .nav-link").click(function () {
  // $(".sliding-tab-content").scrollTop(0);
  // console.log($(".sliding-tab-content").height());
  // controller1 = null;
  // setTimeout(function() {
  //   console.log($(".sliding-tab-content").height());
  //   revealScroll.time(0);
  //   revealScroll.startTime(0);
  //   revealScroll.restart();
  // }, 10);
});

function init() {
  var imgDefer = document.getElementsByTagName("img");
  for (var i = 0; i < imgDefer.length; i++) {
    if (imgDefer[i].getAttribute("data-src")) {
      imgDefer[i].setAttribute("src", imgDefer[i].getAttribute("data-src"));
    }
  }
  jQuery(".home-banner-carousel-right").css("opacity", "1");
  jQuery(".home-banner-carousel .parallax-bg").css("opacity", "1");
}
$(".home-banner-carousel-right .slick-slide img")
  .on("load", function () {})
  .on("error", function () {
    console.log("error loading image");
  });
window.onload = init;

jQuery(function ($) {
  "use strict";
  $(document).ready(function () {
    //for fade effect
    $("body").removeClass("fade-out");
    //remove the overlay
    $("site-navigation").removeClass("nav-open");
    // over team slider controls
    $(".next-btn").click(function () {
      $(".team-slider").slick("slickNext");
    });
    $(".previous-btn").click(function () {
      $(".team-slider").slick("slickPrev");
    });
    // autocomplete off for inputs
    $("input, :input").attr("autocomplete", "off");
  });

  $(document).on("click", ".nav-toggle", function () {
    $(this).toggleClass("on");
    $("html").toggleClass("nav-on");
  });

  $(".team-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    /*asNavFor: '.home-banner-carousel-left',*/
    dots: false,
    focusOnSelect: true,
    arrows: true,
    // centerMode: true,
  });
  $(".app-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    /*asNavFor: '.home-banner-carousel-left',*/
    dots: false,
    focusOnSelect: true,
    arrows: true,
    prevArrow: '<span class="slick-prev"><i class="icon-arrow-left-l"></span>',
    nextArrow: '<span class="slick-next"><i class="icon-arrow-right-l"></span>'
  });
  $(".home-banner-carousel-right").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    /*asNavFor: '.home-banner-carousel-left',*/
    dots: true,
    focusOnSelect: true,
    arrows: true,
    prevArrow: '<span class="slick-prev"><i class="icon-arrow-left-l"></span>',
    nextArrow: '<span class="slick-next"><i class="icon-arrow-right-l"></span>'
  });
  var $sliderText = $(".home-banner-carousel-content-in"),
    $sliderVals = $sliderText.data("values");
  $(".home-banner-carousel-right").on("beforeChange", function (
    event,
    slick,
    currentSlide,
    nextSlide
  ) {
    console.log($sliderVals);
    $sliderText
      .hide()
      .html("<span>" + $sliderVals[nextSlide] + "</span>")
      .fadeIn("2000");
  });
  $(window)
    .on("resize", function () {
      if (document.querySelector(".home-banner-carousel-right")) {
        if ($(window).width() > 1280) {
          $(".home-banner-carousel-right")[0].slick.refresh();
        }
      }
      var $wWindth = $(window).width(),
        $cWidth = $(".site-header > .container-fluid").outerWidth(),
        $pad = ($wWindth - $cWidth) / 2,
        $col1 = $cWidth * (1 / 12) - 20,
        $pad2 = 200,
        $pad3 = 98,
        $iconP = $(".home-banner-carousel-right .slick-dots").outerWidth();
      $(".home-banner-carousel-right .slick-next").css("left", $iconP);

      if ($wWindth >= 1160) {
        $(".home-banner-carousel-right .slick-list").css(
          "padding-right",
          "23%"
        );
        $(".fit-screen-cs .product-feature-list-item-image-in").each(
          function () {
            var $shiftval = $(this).data("shift");
            $(this).css("margin-right", -$shiftval);
          }
        );
      } else if ($wWindth >= 1980) {
        $(".home-banner-carousel-right .slick-list").css(
          "padding-right",
          "46%"
        );
      } else if ($wWindth >= 770 && $wWindth <= 1159) {
        $(".home-banner-carousel-right .slick-list").css(
          "padding-right",
          $pad2
        );
      } else {
        $(".home-banner-carousel-right .slick-list").css(
          "padding-right",
          $pad3
        );
      }
      if ($wWindth <= 991) {
        $(".home-banner-carousel-right .slick-list").css(
          "padding-right",
          "30%"
        );
      }
      if (document.querySelector(".home-products")) {
        setTimeout(function () {
          var div_top = $(".home-products").offset().top;
          if ($(window).width() < 576) {
            $(window).scroll(function () {
              var window_top = $(window).scrollTop() - 0;
              var target_scroll_end = $(".home-no-device").offset().top;
              // console.log(window_top);

              if (window_top > div_top - 50) {
                if (!$(".home-products-wrap .product-tab-list").is(".sticky")) {
                  $(".home-product-item-content").css("padding-top", " 150px");
                  $(".home-products-wrap .product-tab-list").addClass("sticky");
                }
              } else {
                $(".home-products-wrap .product-tab-list").removeClass(
                  "sticky"
                );
                $(".home-product-item-content").css("padding-top", " 0");
              }
              if (window_top > target_scroll_end - 140) {
                $(".home-products-wrap .product-tab-list").removeClass(
                  "sticky"
                );
                $(".home-product-item-content").css("padding-top", " 0");
              }
            });
            $(document).on(
              "click",
              ".home-products-wrap > .product-tab-list.sticky > li > a",
              function () {
                $(window)
                  .scrollTop(div_top + 1)
                  .animate(100);
              }
            );
          } else {
            $(".home-products-wrap .product-tab-list").removeClass("sticky");
          }
        }, 200);
      }
    })
    .resize();
  if ($(window).width() <= 991) {
    $(document).on("click", ".site-navigation>ul>li.has-dropdown>a", function (
      e
    ) {
      e.preventDefault();
      if ($(this).hasClass("on")) {
        $(this)
          .next()
          .slideUp();
        $(this).removeClass("on");
        return;
      }
      $(".site-navigation>ul>li.has-dropdown>a").removeClass("on");
      $(".ms-mega-drop-down").slideUp();
      $(this)
        .next()
        .slideDown();
      $(this).addClass("on");
    });
  }

  $(".home-testimonials-carousel").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '<span class="slick-prev"><i class="icon-slide-l"></span>',
    nextArrow: '<span class="slick-next"><i class="icon-slide-r"></span>',
    fade: true,
    responsive: [{
      breakpoint: 767,
      settings: {
        dots: true,
        fade: false,
        prevArrow: '<span class="slick-prev"><i class="icon-arrow-left-l"></span>',
        nextArrow: '<span class="slick-next"><i class="icon-arrow-right-l"></span>'
      }
    }]
  });
  $(".home-clients-carousel").slick({
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 2000
  });
  $(".related-products-carousel").slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 1,
    arrows: true,
    prevArrow: '<span class="slick-prev"><i class="icon-slide-l"></span>',
    nextArrow: '<span class="slick-next"><i class="icon-slide-r"></span>',
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });

  $(".home-compare-toggle-head").click(function () {
    if ($(this).hasClass("on")) {
      $(this)
        .next()
        .slideUp("normal");
      $(this).removeClass("on");
    }
    // $('.home-compare-toggle-content').slideUp('normal');
    if (
      $(this)
      .next()
      .is(":hidden") == true
    ) {
      $(this).addClass("on");
      $(this)
        .next()
        .slideDown("normal");
    }
  });
  $(window).on("scroll", function () {
    if ($(this).scrollTop() >= 25) {
      $(".site-header").addClass("floating");
    } else {
      $(".site-header").removeClass("floating");
    }
  });
  $(".site-navigation > ul > li.has-dropdown")
    .mouseenter(function () {
      $(".site-navigation").addClass("nav-open");
    })
    .mouseleave(function () {
      $(".site-navigation").removeClass("nav-open");
    });
  // build scene

  var controller = new ScrollMagic.Controller({
    globalSceneOptions: {
      triggerHook: "onLeave"
    }
  });

  var sectionHeight = $(".product-terminal-frames").outerHeight();
  var windowHeight = $(window).height();
  var stages = $(".product-terminal-frames-content-list-item").length;
  var listItems = $(".product-feature-items li").length;
  var listPercent = 100 / listItems;
  var offset = 0;
  var HasTap = $(".ptfcli-progress-in.tap").length;
  var offsetvar = 300;
  var headerHeight = 76;

  $(window)
    .on("resize", function () {
      updateOffset();
    })
    .resize();

  $(window).on("load", function () {
    initScene();
    updateOffset();
  });

  function updateOffset() {
    var floatingHeader = $(".site-header.floating");
    if (floatingHeader.outerHeight()) {
      headerHeight = floatingHeader.outerHeight();
    }
    var sticySectionTitle = $(".product-terminal-frames .section-title");
    var sticySectionTitleOffset =
      sticySectionTitle.outerHeight() +
      parseInt(sticySectionTitle.css("margin-bottom")) +
      parseInt(sticySectionTitle.css("margin-top"));
    var stickySection = $(".has-animation");
    var stickySectionOffset = parseInt(stickySection.css("padding-top"));

    var scrollingContent = $(".product-terminal-frames-content");
    var machineImage = $(".mswipe-device-card");
    var mobileWindowHeight = $(window).height();

    var scrollingContentHeading = $(
      ".product-terminal-frames-content-list-item"
    );
    var progressBar = $(".product-terminal-frames-content-list-item-in");
    var scrollingContentOffset =
      scrollingContent.outerHeight() +
      parseInt(scrollingContentHeading.css("margin-top")) +
      parseInt(scrollingContentHeading.css("margin-bottom")) +
      parseInt(progressBar.outerHeight());
    offset = stickySectionOffset + sticySectionTitleOffset - headerHeight;

    var mobilelHeightOffset =
      mobileWindowHeight -
      headerHeight -
      (scrollingContentOffset + machineImage.outerHeight());

    if ($(window).width() <= 767) {
      if (floatingHeader.outerHeight()) {
        headerHeight = floatingHeader.outerHeight();
      } else {
        headerHeight = 51;
      }
      offset =
        stickySectionOffset +
        sticySectionTitleOffset -
        headerHeight -
        mobilelHeightOffset;
      $(".product-terminal-frames-content-wrap").css(
        "height",
        scrollingContentOffset
      );
    } else {
      $(".product-terminal-frames-content-wrap").css("height", "auto");
      if (sectionHeight >= windowHeight - headerHeight) {
        if (machineImage.outerHeight() >= windowHeight - headerHeight) {
          offset =
            stickySectionOffset +
            sticySectionTitleOffset -
            headerHeight +
            (machineImage.outerHeight() - (windowHeight - headerHeight)) +
            10;
        }
      } else {
        offset = 0;
      }
    }
    if (startpin) {
      startpin.refresh();
      if (swipeScene) {
        swipeScene.refresh();
      }
      if (tapScene) {
        tapScene.refresh();
      }
      if (insertScene) {
        insertScene.refresh();
      }
      if (scrollScene) {
        scrollScene.refresh();
      }
    }
    console.log("offset", offset);
  }

  var startpin,
    swipeScene,
    offsetvar,
    tapScene,
    insertScene,
    ledBlinkScene,
    reveal,
    scrollSceneMoneyStore,
    scrollScene;

  function initScene() {
    startpin = new ScrollMagic.Scene({
        triggerElement: ".has-animation",
        duration: stages * 1000 + listItems * 1000,
        offset: 200
      })
      .setPin(".has-animation")
      .addTo(controller);

    swipeScene = new ScrollMagic.Scene({
        triggerElement: ".has-animation",
        duration: 1000,
        offset: offset
      })
      .setTween(TweenMax.to(".ptfcli-progress-in.swipe", 1, {
        width: "100%"
      }))
      .addTo(controller) // assign the scene to the controller
      .on("enter", function (e) {
        //  console.log(e);
        $(".product-terminal-frames-content-list-item:nth-child(1)")
          .addClass("active")
          .find(".product-terminal-frames-content-list-item-in")
          .slideDown();
        $(".mswipe-device-card")
          .removeClass("card-insert")
          .addClass("card-swipe");
      })
      .on("leave", function (e) {
        $(".product-terminal-frames-content-list-item:nth-child(1)")
          .removeClass("active")
          .find(".product-terminal-frames-content-list-item-in")
          .slideUp();
      })
      .on("progress", function (e) {
        $(".img-card-swipe").css(
          "transform",
          "translateX(" + -e.progress * 600 + "px)"
        );
        $(".img-card-s").css(
          "transform",
          "translateY(" + e.progress * 600 + "px)"
        );
      });
    if (HasTap) {
      offsetvar = 1000;
      tapScene = new ScrollMagic.Scene({
          triggerElement: ".has-animation",
          duration: 1000,
          offset: offset + 1000
        })
        .setTween(TweenMax.to(".ptfcli-progress-in.tap", 1, {
          width: "100%"
        }))
        .addTo(controller) // assign the scene to the controller
        .on("enter", function (e) {
          $(".product-terminal-frames-content-list-item:nth-child(2)")
            .addClass("active")
            .find(".product-terminal-frames-content-list-item-in")
            .slideDown();
          $(".mswipe-device-card")
            .removeClass("card-insert card-swipe")
            .addClass("card-tap");
        })
        .on("leave", function (e) {
          $(".product-terminal-frames-content-list-item:nth-child(2)")
            .removeClass("active")
            .find(".product-terminal-frames-content-list-item-in")
            .slideUp();
          $(".mswipe-device-card").removeClass("card-tap");
        })
        .on("progress", function (e) {
          $(".img-card-tap").css(
            "transform",
            "scale(" + (1 - e.progress / 5) + ")"
          );
        });
    }

    insertScene = new ScrollMagic.Scene({
        triggerElement: ".has-animation",
        duration: 1000,
        offset: 1300 + offsetvar
      })
      .setTween(TweenMax.to(".ptfcli-progress-in.insert", 1, {
        width: "100%"
      }))
      .addTo(controller) // assign the scene to the controller
      .on("enter", function (e) {
        //  console.log(e);card-insert
        if (HasTap) {
          $(".product-terminal-frames-content-list-item:nth-child(3)")
            .addClass("active")
            .find(".product-terminal-frames-content-list-item-in")
            .slideDown();
        } else {
          $(".product-terminal-frames-content-list-item:nth-child(2)")
            .addClass("active")
            .find(".product-terminal-frames-content-list-item-in")
            .slideDown();
        }
        $(".mswipe-device-card")
          .removeClass("card-swipe")
          .addClass("card-insert");
      })
      .on("leave", function (e) {
        //  console.log(e);
        if (HasTap) {
          $(".product-terminal-frames-content-list-item:nth-child(3)")
            .removeClass("active")
            .find(".product-terminal-frames-content-list-item-in")
            .slideUp();
        } else {
          $(".product-terminal-frames-content-list-item:nth-child(2)")
            .removeClass("active")
            .find(".product-terminal-frames-content-list-item-in")
            .slideUp();
        }
        //$('.mswipe-device-card').removeClass('');
      })
      .on("progress", function (e) {
        $(".img-card-insert").css(
          "transform",
          "translate(-50%, " + -e.progress * 420 + "px)"
        );
      });

    reveal = new TimelineMax().add([
      TweenMax.to(".product-feature-items", 0.01, {
        opacity: 1
      }),
      TweenMax.to(".product-terminal-frames-content", 1, {
        top: -$(".product-feature-items").height()
      })
    ]);
    scrollScene = new ScrollMagic.Scene({
        triggerElement: ".has-animation",
        duration: listItems * 1000,
        offset: offset + 1000 * stages
      })
      .setTween(reveal)
      .addTo(controller) // assign the scene to the controller
      .on("progress", function (e) {
        if (e.scrollDirection === "FORWARD") {
          $(".product-feature-items li")
            .eq(Math.ceil((e.progress * 100) / listPercent) - 1)
            .css({
              opacity: 1
            });
        } else {
          $(".product-feature-items li")
            .eq(Math.ceil((e.progress * 100) / listPercent) - 1)
            .css({
              opacity: 0.2
            });
        }
      });
  }

  function scrollFix() {
    if ($(".falcon-qr-in-action-floating").length) {
      var top = $(".falcon-qr-in-action-floating").offset().top;
      var footTop =
        $(".falcon-qr-in-action-fix").offset().top -
        parseFloat(
          $(".falcon-qr-in-action-fix")
          .css("marginTop")
          .replace(/auto/, 0)
        );

      var maxY = footTop - $(".falcon-qr-in-action-floating").outerHeight();

      $(window).scroll(function (evt) {
        var y = $(this).scrollTop();
        if ($(this).width() >= 992) {
          if (y > top) {
            if (y < maxY) {
              $(".falcon-qr-in-action-floating")
                .addClass("fixed")
                .removeAttr("style");
            } else {
              $(".falcon-qr-in-action-floating")
                .removeClass("fixed")
                .css({
                  position: "absolute",
                  top: maxY - top + "px"
                });
            }
          } else {
            $(".falcon-qr-in-action-floating").removeClass("fixed");
          }
        }
      });
    }
  }
  $(window)
    .on("resize", function () {
      if ($(window).width() <= 991) {
        $(".falcon-qr-in-action-images").slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
          prevArrow: '<span class="slick-prev"><i class="icon-slide-l"></span>',
          nextArrow: '<span class="slick-next"><i class="icon-slide-r"></span>',
          responsive: [{
              breakpoint: 850,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 466,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
        $(".falcon-qr-in-action-floating")
          .removeClass("fixed")
          .removeAttr("style");
      } else {
        if ($(".falcon-qr-in-action-images").hasClass("slick-slider")) {
          $(".falcon-qr-in-action-images").slick("unslick");
        }
        setTimeout(function () {
          scrollFix();
        }, 3000);
      }
    })
    .resize();
  $(window).enllax();
  if (document.querySelector(".product-compare")) {
    $(window).scroll(function () {
      var window_top = $(window).scrollTop() - 0;
      var div_top = $(".product-compare").offset().top;
      var div_bottom = $(".product-support").offset().top;
      var contaier_width = $(".container-fluid").innerWidth();
      $(".sticky-table-header").css("width", contaier_width);
      // console.log(window_top);

      if (window_top > div_top + 50) {
        if (!$(".sticky-table-header").is(".sticky")) {
          $(".sticky-table-header").addClass("show-table-header");
        }
      } else {
        $(".sticky-table-header").removeClass("show-table-header");
      }
      if (window_top > div_bottom - 140) {
        $(".sticky-table-header").removeClass("show-table-header");
      }
    });
  }
});